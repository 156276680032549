import { IntlProvider } from 'react-intl';
import type { LayoutRouteProps, LinksFunction } from 'react-router';
import { Links, Meta, Outlet, Scripts, ScrollRestoration } from 'react-router';

import { LoadingSpinner } from '@shieldpay/bumblebee/loading-spinner';

import { hiveTheme } from '@shieldpay/theme-provider-ui/theme/hive';

import hiveMessages from '@shieldpay/i18n/hive/en-GB.json';
import defaultMessages from '@shieldpay/i18n/shared/en-GB.json';

export const links: LinksFunction = () => [
  {
    rel: 'stylesheet',
    href: 'https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap',
    media: 'all',
  },
];

export function Layout({ children }: LayoutRouteProps) {
  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
      </head>
      {/* children will be the root Component, ErrorBoundary, or HydrateFallback */}
      <IntlProvider
        locale={'en-GB'} //TODO
        messages={{ ...defaultMessages, ...hiveMessages }}
      >
        <body className={hiveTheme}>
          {children}
          <ScrollRestoration />
          <Scripts />
        </body>
      </IntlProvider>
    </html>
  );
}

export default Outlet;

export function HydrateFallback() {
  return <LoadingSpinner center size="large" />;
}
