import type {
  AllHTMLAttributes,
  CSSProperties,
  ElementType,
  ReactNode,
  Ref,
} from 'react';
import type { SpringValue } from '@react-spring/web';

import { classFilter } from '@shieldpay/theme-provider-ui/utilities';

import type { Display, GetBoxStyleProps } from './get-box-styles';
import { getBoxStyles } from './get-box-styles';

export type BoxProps<Element = HTMLElement> = GetBoxStyleProps &
  Pick<
    AllHTMLAttributes<Element>,
    /** carefully add any html attributes we might use here when needed */
    | 'disabled'
    | 'onClick'
    | 'onFocus'
    | 'onBlur'
    | 'onChange'
    | 'onSubmit'
    | 'htmlFor'
    | 'id'
    | 'method'
    | 'name'
    | 'role'
    | 'className'
    | 'hidden'
    | 'checked'
    | 'type'
    | 'value'
    | 'defaultValue'
    | 'title'
    | 'placeholder'
    | 'autoComplete'
    | 'type'
    | 'target'
    | 'href'
    | 'download'
    | 'readOnly'
  > & {
    Component?: ElementType;
    ref?: Ref<Element>;
    style?: Record<string, SpringValue> | CSSProperties;
    children?: ReactNode;
  } & (
    | {
        /** special case to be used for NavLink components */
        to?: string;
        preventScrollReset?: boolean;
        prefetch?: 'none' | 'intent' | 'render' | 'viewport';
      }
    | {
        to?: never;
        preventScrollReset?: never;
        prefetch?: never;
      }
  );

export const filterDisplayProps = ({
  variant,
  alignContent,
  stack,
}: Pick<Display, 'variant' | 'alignContent' | 'stack'>) =>
  variant === 'initial'
    ? {
        variant,
      }
    : variant === 'grid'
      ? {
          variant,
          alignContent,
        }
      : ({
          variant,
          alignContent,
          stack,
        } satisfies Display);

export const Box = <Element,>({
  Component = 'div',
  background,
  color,
  children,
  stack,
  variant = 'flex',
  spacing,
  padding,
  alignContent,
  touchArea,
  rounded,
  expand,
  style, //this is meant to be used by react-spring for animations, not general styling.
  size,
  constrain,
  border,
  gridItem,
  className,
  hide,
  opacity,
  ref,
  ...restProps
}: BoxProps<Element>) => {
  return (
    <Component
      style={style}
      {...restProps}
      className={classFilter([
        getBoxStyles({
          background,
          border,
          color,
          padding,
          spacing,
          rounded,
          expand,
          touchArea,
          size,
          constrain,
          gridItem,
          hide,
          opacity,
          ...filterDisplayProps({ variant, alignContent, stack }),
        }),
        className,
      ])}
      ref={ref}
    >
      {children}
    </Component>
  );
};
